import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application

export { application }

import AccountShowTabController from "./account_show_tab_controller.js"
application.register("account-show-tab", AccountShowTabController)

import AddonBudgetFormController from "./addon_budget_form_controller.js"
application.register("addon-budget-form", AddonBudgetFormController)

import BudgetTemplateItemController from "./budget_template_item_controller.js"
application.register("budget-template-item", BudgetTemplateItemController)

import BudgetTemplatesController from "./budget_templates_controller.js"
application.register("budget-templates", BudgetTemplatesController)

import DynamicFormController from "./dynamic_form_controller.js"
application.register("dynamic-form", DynamicFormController)

import GenericBudgetTemplatesController from "./generic_budget_templates_controller.js"
application.register("generic-budget-templates", GenericBudgetTemplatesController)

import GenericPreproposalController from "./generic_preproposal_controller.js"
application.register("generic-preproposal", GenericPreproposalController)

import GenericSupplierRoleController from "./generic_supplier_roles_controller.js"
application.register("generic-supplier-roles", GenericSupplierRoleController)

import InvoiceFormController from "./invoice_form_controller.js"
application.register("invoice-form", InvoiceFormController)

import InvoiceLineController from "./invoice_line_controller.js"
application.register("invoice-line", InvoiceLineController)

import MentionController from "./mention_controller.js"
application.register("mention", MentionController)

import NestedFormController from "./nested_form_controller.js"
application.register("nested-form", NestedFormController)

import NumberFieldController from "./number_field_controller.js"
application.register("number-field", NumberFieldController)

import OfferLinkController from "./offer_link_controller.js"
application.register("offer-link", OfferLinkController)

import PreproposalFilterController from "./preproposal_filter_controller.js"
application.register("preproposal-filter", PreproposalFilterController)

import PreproposalSettingsGeneralController from "./preproposal_settings_general_controller.js"
application.register("preproposal-settings-general", PreproposalSettingsGeneralController)

import PreproposalSettingsTableController from "./preproposal_settings_table_controller.js"
application.register("preproposal-settings-table", PreproposalSettingsTableController)

import PrintWindowController from "./print_window_controller.js"
application.register("print-window", PrintWindowController)

import ProjectEditFormController from "./project_edit_form_controller.js"
application.register("project-edit-form", ProjectEditFormController)

import ProjectExpensesController from "./project_expenses_controller.js"
application.register("project-expenses", ProjectExpensesController)

import ProjectShowTabController from "./project_show_tab_controller.js"
application.register("project-show-tab", ProjectShowTabController)

import ProjectSuppliersController from "./project_suppliers_controller.js"
application.register("project-suppliers", ProjectSuppliersController)

import ProposalsController from "./proposals_controller.js"
application.register("proposals", ProposalsController)

import ShowPasswordController from "./show_password_controller.js"
application.register("show-password", ShowPasswordController)

import SubmitFormController from "./submit_form_controller.js"
application.register("submit-form", SubmitFormController)

import SupplierNoteRolesController from "./supplier_note_roles_controller.js"
application.register("supplier-note-roles", SupplierNoteRolesController)

import SupplierRoleFormController from "./supplier_role_form_controller.js"
application.register("supplier-role-form", SupplierRoleFormController)

import TradeNameController from "./trade_name_controller.js"
application.register("trade-name", TradeNameController)

import VideosFormController from "./videos_form_controller.js"
application.register("videos-form", VideosFormController)

import VideosIndexController from "./videos_index_controller.js"
application.register("videos-index", VideosIndexController)

import ViesSearchController from "./vies_search_controller.js"
application.register("vies-search", ViesSearchController)

import WorkflowDatepickerController from "./workflow_datepicker_controller.js"
application.register("workflow-datepicker", WorkflowDatepickerController)

import InvoicesActionsController from "./invoices_actions_controller.js"
application.register("invoices-actions", InvoicesActionsController)

import AlertsController from "./alerts_controller.js"
application.register("alerts", AlertsController)

import PasswordValidationsController from "./password_validations_controller.js"
application.register("password-validations", PasswordValidationsController)

import InvoiceSendEmailController from "./invoice_send_email_controller.js"
application.register("invoice-send-email", InvoiceSendEmailController)

import MassCheckboxController from "./mass_checkbox_controller.js"
application.register("mass-checkbox", MassCheckboxController)

import ServiceLinesFormController from "./service_lines_form_controller.js"
application.register("service-lines-form", ServiceLinesFormController)

import InvoiceLineServiceController from "./invoice_line_service_controller.js"
application.register("invoice-line-service", InvoiceLineServiceController)

import MydataValidationsServiceController from "./mydata_validations_controller.js"
application.register("mydata-validations", MydataValidationsServiceController)

import CashflowController from "./cashflow_controller.js"
application.register("cashflow", CashflowController)

import CreditCorrelated from "./credit_correlated_controller.js"
application.register("credit-correlated", CreditCorrelated)

import ClickableRowLinks from "./clickable_row_links_controller.js"
application.register("clickable-row-links", ClickableRowLinks)

import CalendarController from "./calendar_controller.js"
application.register("calendar", CalendarController)

import S4gSwitchController from "./s4g_switch_controller.js"
application.register("s4g-switch", S4gSwitchController)

import FlashController from "./flash_controller"
application.register("flash", FlashController)

import SupplierFormController from "./supplier_form_controller"
application.register("supplier-form", SupplierFormController)

import ProposalDownloadController from "./proposal_download_controller"
application.register("proposal-download", ProposalDownloadController)

import ProposalItemController from "./proposal_item_controller"
application.register("proposal-item", ProposalItemController)

import LeaveConfirmationController from "./leave_confirmation_controller"
application.register("leave-confirmation", LeaveConfirmationController)

import ProjectExpenseInvoicesController from "./project_expense_invoices_controller"
application.register("project-expense-invoices", ProjectExpenseInvoicesController)

import ProjectSupplierInvoicesController from "./project_supplier_invoices_controller"
application.register("project-supplier-invoices", ProjectSupplierInvoicesController)

import ProposalSectionController from "./proposal_section_controller"
application.register("proposal-section", ProposalSectionController)

import ProposalSectionsController from "./proposal_sections_controller"
application.register("proposal-sections", ProposalSectionsController)

import InvoiceLinkController from "./invoice_link_controller"
application.register("invoice-link", InvoiceLinkController)

import SortableController from "./sortable_controller"
application.register("sortable", SortableController)

import InvoiceProjectSelectController from "./invoice_project_select_controller"
application.register("invoice-project-select", InvoiceProjectSelectController)

import ProducerProjectsForm from "./producer_projects_form_controller"
application.register("producer-projects-form", ProducerProjectsForm)

import ProposalTotalsController from "./proposal_totals_controller"
application.register("proposal-totals", ProposalTotalsController)

import PreviewSelectionsController from "./preview_selections_controller"
application.register("preview-selections", PreviewSelectionsController)

import ProjectSectionController from "./project_section_controller"
application.register("project-section", ProjectSectionController)

import HideShowToggleController from "./hide_show_toggle_controller"
application.register("hide-show-toggle", HideShowToggleController)

import PaymentDetailController from "./payment_detail_controller"
application.register("payment-detail", PaymentDetailController)

import ProjectTabController from "./project_tab_controller"
application.register("project-tab", ProjectTabController)

import QrScannerController from "./qr_scanner_controller"
application.register("qr-scanner", QrScannerController)

import ManualExpenseController from "./manual_expense_controller"
application.register("manual-expense", ManualExpenseController)

import PartialPaymentsController from "./partial_payments_controller"
application.register("partial-payments", PartialPaymentsController)

import AccountIncomeStatementController from "./account_income_statement_controller"
application.register("account-income-statement", AccountIncomeStatementController)

import ChartController from "./chart_controller"
application.register("chart", ChartController)

import OutsideClickController from "./outside_click_controller"
application.register("outside-click", OutsideClickController)

import CookieBotController from "./cookie_bot_controller"
application.register("cookie-bot", CookieBotController)

import WrappSelectController from "./wrapp_select_controller"
application.register("wrapp-select", WrappSelectController)

import ParallaxController from "./parallax_controller"
application.register("parallax", ParallaxController)

import NewAccountOption from "./new_account_option_controller"
application.register("new-account-option", NewAccountOption)

import ModalsController from "./modals_controller"
application.register("modals", ModalsController)

import ConfettiController from "./confetti_controller"
application.register("confetti", ConfettiController)

import InvoicePreferences from "./invoice_preferences_controller"
application.register("invoice-preferences", InvoicePreferences)

import AroundSaas1Controller from "./around_saas1_controller"
application.register('around-saas1', AroundSaas1Controller)

import DeductionFormController from "./deduction_form_controller"
application.register('deduction-form', DeductionFormController)

import StandalonePaymentsFormController from "./standalone_payments_form_controller"
application.register('standalone-payments-form', StandalonePaymentsFormController)

import AccomondationFormFormController from "./accomondation_form_controller"
application.register('accomondation-form', AccomondationFormFormController)

import AccountNewEditFormController from "./account_new_edit_form_controller"
application.register('account-new-edit-form', AccountNewEditFormController)

import CountUpFormController from "./count_up_controller"
application.register('count-up', CountUpFormController)

import ChartsController from "./charts_controller"
application.register("charts", ChartsController)

import PhoneInputController from "./phone_input_controller"
application.register("phone-input", PhoneInputController)

import PasswordModalController from "./password_modal_controller"
application.register("password-modal", PasswordModalController)

import GenericController from "./generic_controller"
application.register("generic", GenericController)

import PriceSwitchController from "./price_switch_controller"
application.register("price-switch", PriceSwitchController)

import AccountAddressesController from "./account_addresses_controller"
application.register("account-addresses", AccountAddressesController)

import BaseDiscountWidgetController from "./base_discount_widget_controller"
application.register("base-discount-widget", BaseDiscountWidgetController)

import DiscountWidgetController from "./discount_widget_controller"
application.register("discount-widget", DiscountWidgetController)

import ClassificationSelectsController from "./classification_selects_controller"
application.register("classification-selects", ClassificationSelectsController)

import InvoiceDeliveryController from "./invoice_delivery_controller"
application.register("invoice-delivery", InvoiceDeliveryController)

import DownloadZipFileController from "./download_zip_file_controller"
application.register("download-zip-file", DownloadZipFileController)

import DropzoneController from "./dropzone_controller"
application.register("dropzone", DropzoneController)

import DropfilesController from "./dropfiles_controller"
application.register("dropfiles", DropfilesController)

import BilligBooksController from "./billing_books_controller"
application.register("billing-books", BilligBooksController)

import RecurringScheduleFormController from "./recurring_schedule_form_controller"
application.register("recurring-schedule-form", RecurringScheduleFormController)

import CashRegisterController from "./cash_register_controller"
application.register("cash-register", CashRegisterController)

import RetailServiceLinesController from "./retail_service_lines_controller"
application.register("retail-service-lines", RetailServiceLinesController)

import ThermalPrintController from "./thermal_print_controller"
application.register("thermal-print", ThermalPrintController)

import SearchableInputController from "./searchable_input_controller"
application.register("searchable-input", SearchableInputController)

import PdfBtnController from "./pdf_btn_controller"
application.register("pdf-btn", PdfBtnController)

import PersonalAccountModalFormController from "./personal_account_modal_form_controller"
application.register("personal-account-modal-form", PersonalAccountModalFormController)

import PosPaymentsController from "./pos_payments_controller"
application.register("pos-payments", PosPaymentsController)

import BillingBookOptionsController from "./billing_book_options_controller"
application.register("billing-book-options", BillingBookOptionsController)

import SimplifiedInvoiceLineController from "./simplified_invoice_line_controller"
application.register("simplified-invoice-line", SimplifiedInvoiceLineController)

import CateringTableController from "./catering_table_controller"
application.register("catering-table", CateringTableController)

import SupportPlansSwitcherController from "./support_plans_switcher_controller"
application.register("support-plans-switcher", SupportPlansSwitcherController)
